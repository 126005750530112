import React, { useEffect } from 'react';
import Cookies from 'js-cookie';

const UtmCookies = () => {
  useEffect(() => {
    const searchEngines = ['google', 'bing', 'yahoo', 'baidu', 'duckduckgo'];
    let utm_source = 'Direct';
    let utm_medium = '-';
    let utm_campaign = '-';
    let utm_adgroup = '-';
    let utm_term = '-';
    let gclid = '-';

    if (!Cookies.get('utm_source')) {
      if (window.location.search.includes('utm_source')) {
        const urlParams = new URLSearchParams(window.location.search);
        utm_source = urlParams.get('utm_source') || '';
        utm_medium = urlParams.get('utm_medium') || '';
        utm_campaign = urlParams.get('utm_campaign') ||  '';
        utm_adgroup = urlParams.get('utm_adgroup') || '';
        utm_term = urlParams.get('utm_term') || '';
        gclid = urlParams.get('gclid') || '';
      } else if (document.referrer) {
        let host = new URL(document.referrer).hostname;
        host = host.replace('www.', '').replace('.com', '').replace('.in', '')
          .replace('.net', '').replace('.org', '').replace('in.', '').replace('search.', '');

        const serverName = window.location.hostname;

        if (!serverName.includes(host)) {
          utm_source = host;
          utm_medium = searchEngines.includes(host) ? 'Organic' : host;
        }
      }
      
      Cookies.set('utm_source', utm_source, { expires: 30 });
      Cookies.set('utm_medium', utm_medium, { expires: 30 });
      Cookies.set('utm_campaign', utm_campaign, { expires: 30 });
      Cookies.set('utm_adgroup', utm_adgroup, { expires: 30 });
      Cookies.set('utm_term', utm_term, { expires: 30 });
      Cookies.set('gclid', gclid, { expires: 30 });
    } else if (window.location.search.includes('utm_source')) {
      const urlParams = new URLSearchParams(window.location.search);
      utm_source = urlParams.get('utm_source') || '';
      utm_medium = urlParams.get('utm_medium') || '';
      utm_campaign = urlParams.get('utm_campaign') || '';
      utm_adgroup = urlParams.get('utm_adgroup') || '';
      utm_term = urlParams.get('utm_term') || '';
      gclid = urlParams.get('gclid') || '';

      Cookies.set('utm_source', utm_source, { expires: 30 });
      Cookies.set('utm_medium', utm_medium, { expires: 30 });
      Cookies.set('utm_campaign', utm_campaign, { expires: 30 });
      Cookies.set('utm_adgroup', utm_adgroup, { expires: 30 });
      Cookies.set('utm_term', utm_term, { expires: 30 });
      Cookies.set('gclid', gclid, { expires: 30 });
    }
  }, []);

  return null;
};

export default UtmCookies;