import * as React from "react"
const ExternalLinks=()=>{
return <>
<link
      rel="stylesheet"
      href="https://storage.googleapis.com/smartphotoedits/css/style.css"
    />

 <link
      rel="stylesheet"
      href="https://storage.googleapis.com/smartphotoedits/Fonts/Fonts.css"
    />   
</>
}
export default ExternalLinks